<template >
  <div style="margin-top:0rem" class="content">
    <div class="headArea">
      <img :src="imgSrc" style="width:100%;height:100%" />
      <div class="headLine">
        <div class="headLineLeft">
          <div class="blueArea"></div>
          <div class="titleFont">泰顺廊桥氡泉旅游度假区</div>
        </div>
        <div class="headLineRight">{{headDate}}</div>
      </div>
    </div>
    <div class="analysisBigTab">
      <div class="analysisBigTabHead">
        <div style="height:0.653rem"></div>
        <div class="analysisBigTabHeadTitle">
          <div class="analysisBigTabHeadTitleOne">实时人数</div>
          <div class="analysisBigTabHeadTitleTwo">数据更新时间：{{realTime.time}}</div>
        </div>
        <div class="analisisCount">{{realTime.count}}</div>
      </div>
      <div class="analysisDataTitle">景区实时人数分布</div>
      <div style="height:0.653rem"></div>
      <div class="dataline" v-for="item in dataList" :key="item.name">
        <div class="datalineTitle">
          <div style="margin-right:0.267rem">{{item.name}}</div>
        </div>
        <!-- <view class="datalineWidth" style="width:{{item.amount*item.multiple}}rpx"></view> -->
        <div class="datalineWidthBack">
          <div class="datalineWidthContent" :style="getH5Width(item.width)"></div>
        </div>
        <div class="datalineNumber">{{item.multiple}}%</div>
      </div>
      <div style="height:0.48rem"></div>
    </div>
    <div style="height:0.667rem"></div>
  </div>
</template>

<script>
import { Notify } from 'vant';
export default {
  name: 'dataAnalysisDetail',

  data() {
    return {
      imgSrc: '',
      headDate: '10/12.12',
      realTime: {
        time: '2019-11-1 12:25:23',
        count: '19,578'
      },
      dataList: [
        {
          name: '廊桥文化园景区',
          amount: 5000,
          multiple: 2.8
        },
        {
          name: '氡泉风景区',
          amount: 14578,
          multiple: 2.8
        }
      ]
    };
  },
  watch: {},
  created() {
    this.getRecords();
    this.getMultipleCount();
  },
  destroyed() {
    // window.removeEventListener('hashchange', this.afterQRScan)
  },
  methods: {
    getRecords() {
      const this_ = this;
      this_.$api
        .getContentByPage({ topicSymbol: 'min-home-banner' })
        .then(res => {
          if (res.data.code === 10000) {
            this_.imgSrc =
              res.data.data.records.length > 0
                ? res.data.data.records[0].content.bannerPic
                : '';
          } else {
            Notify({ type: 'warning', message: res.data.msg });
          }
        });
    },
    getMultipleCount() {
      const that = this;
      const dataList = this.dataList;
      let countTemp = 0;
      dataList.forEach(item => {
        countTemp += item.amount;
      });
      dataList.forEach(item => {
        item.multiple = parseFloat((item.amount / countTemp) * 100).toFixed(1);
        item.width = parseFloat((item.amount / countTemp) * 390).toFixed(1);
      });
      console.log(dataList);
      // 获取时间
      const realTime = that.realTime;
      let headDate = '';
      const date = new Date();
      const xingqi = that.getDay(date.getDay());
      headDate = `${date.getMonth() + 1}月${date.getDate()}日(${xingqi})`;
      realTime.time = that.getDateString(date);
      that.dataList = dataList;
      that.headDate = headDate;
      that.realTime = realTime;
    },
    getDay(number) {
      let date = '';
      switch (number) {
        case 0:
          date = '周日';
          break;
        case 1:
          date = '周一';
          break;
        case 2:
          date = '周二';
          break;
        case 3:
          date = '周三';
          break;
        case 4:
          date = '周四';
          break;
        case 5:
          date = '周五';
          break;
        case 6:
          date = '周六';
          break;
        default:
          date = '周六';
      }
      return date;
    },
    getDateString(date) {
      const year = date.getFullYear();
      const month = date.getMonth() + 1;
      const day = date.getDate();
      const hour = date.getHours();
      const minute = date.getMinutes();
      const second = date.getSeconds();
      const formatNumber = n => {
        n = n.toString();
        return n[1] ? n : '0' + n;
      };

      return (
        year +
        '-' +
        month +
        '-' +
        day +
        ' ' +
        [hour, minute].map(formatNumber).join(':')
      );
    },
    getH5Width(width) {
      return `width:${parseFloat(width / 75).toFixed(3)}rem`;
    }
  }
};
</script>
<style scoped>
.content {
  width: 100%;
  height: 100%;
  position: relative;
}
.headArea {
  height: 5.43rem;
  width: 100%;
  background-color: gray;
  position: relative;
}

.headLine {
  height: 0.56;
  width: 100%;
  position: absolute;
  bottom: 0.36rem;
  left: 0rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.headLineLeft {
  margin-left: 0.32rem;
  width: 4.67rem;
  display: flex;
  align-items: center;
}

.headLineRight {
  margin-right: 0.32rem;
  display: flex;
  align-items: center;
  font-size: 0.347rem;
  color: #fff;
}
.blueArea {
  width: 0.107rem;
  height: 0.427rem;
  background-color: #00a5ff;
  opacity: 1;
}

.titleFont {
  width: 4.4rem;
  height: 0.56rem;
  margin-left: 0.093rem;
  font-size: 0.4rem;
  color: #fff;
  font-weight: 500;
}
.tabLine {
  height: 2.2rem;
  width: 100%;
  margin-top: 0.32rem;
  display: flex;
  justify-content: space-between;
}

.leftTab {
  height: 100%;
  width: 4.52rem;
  margin-left: 0.32rem;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0rem 0.027rem 0.16rem 0rem rgba(0, 0, 0, 0.14);
  border-radius: 0.213rem;
}

.rightTab {
  height: 100%;
  width: 4.52rem;
  margin-right: 0.32rem;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0rem 0.027rem 0.16rem 0rem rgba(0, 0, 0, 0.14);
  border-radius: 0.213rem;
}

.contentLine {
  height: 1.333rem;
  margin-top: 0.413rem;
  margin-left: 0.533rem;
  display: flex;
}

.tabIcon {
  width: 1.333rem;
  height: 100%;
}

.tabFontLine {
  height: 100%;
  width: 1.733rem;
  margin-left: 0.267rem;
}

.tabTitleText {
  margin-top: 0.08rem;
  width: 1.4rem;
  height: 0.493rem;
  font-size: 0.347rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(136, 136, 136, 1);
  line-height: 0.493rem;
}

.tabContentText {
  margin-top: 0.107rem;
  width: 1.6rem;
  height: 0.56rem;
  font-size: 0.4rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: rgba(51, 51, 51, 1);
  line-height: 0.56rem;
}
.analysisBigTab {
  margin-top: 0.32rem;
  margin-left: 0.32rem;
  margin-right: 0.32rem;
  /* height: 683rpx; */
  background: rgba(255, 255, 255, 1);
  box-shadow: 0rem 0.027rem 0.16rem 0rem rgba(0, 0, 0, 0.14);
  border-radius: 0.213rem;
}

.analysisBigTabHead {
  height: 2.653rem;
  border-bottom: #eee 0.013rem solid;
}

.analysisBigTabHeadTitle {
  height: 0.427rem;
  margin-left: 0.48rem;
  display: flex;
  align-items: flex-end;
}

.analysisBigTabHeadTitleOne {
  font-size: 0.4rem;
  color: rgba(34, 34, 34, 1);
}

.analysisBigTabHeadTitleTwo {
  margin-left: 0.267rem;
  font-size: 0.347rem;
  color: rgba(136, 136, 136, 1);
}

.analisisCount {
  margin-top: 0.253rem;
  margin-left: 0.48rem;
  width: 2.68rem;
  height: 0.827rem;
  font-size: 0.587rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: rgba(0, 165, 255, 1);
  line-height: 0.827rem;
}

.analysisDataTitle {
  margin-top: 0.48rem;
  text-align: center;
  height: 0.56rem;
  font-size: 0.4rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(34, 34, 34, 1);
  line-height: 0.56rem;
}

.dataline {
  height: 0.533rem;
  width: 100%;
  margin-bottom: 0.32rem;
  display: flex;
  align-items: center;
}

.datalineTitle {
  height: 100%;
  width: 2.667rem;
  font-size: 0.32rem;
  color: rgba(136, 136, 136, 1);
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.datalineWidth {
  height: 100%;
  background: linear-gradient(
    270deg,
    rgba(0, 209, 255, 1) 0%,
    rgba(0, 165, 255, 1) 100%
  );
  box-shadow: 0rem 0.027rem 0.053rem 0rem rgba(0, 0, 0, 0.18);
}

.datalineWidthBack {
  width: 5.2rem;
  height: 100%;
  background: rgba(208, 238, 255, 1);
  border-radius: 0.267rem;
}

.datalineWidthContent {
  height: 100%;
  background: linear-gradient(
    270deg,
    rgba(0, 209, 255, 1) 0%,
    rgba(0, 165, 255, 1) 100%
  );
  box-shadow: 0rem 0.027rem 0.053rem 0rem rgba(0, 0, 0, 0.18);
  border-radius: 0.267rem;
}

.datalineNumber {
  margin-left: 0.347rem;
  font-size: 0.32rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(51, 51, 51, 1);
}
</style>



